<template>
	<div>
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import CompanyLayoutVertical from "@core/layouts/layout-vertical/CompanyLayoutVertical.vue";
import HatcheryLayoutVertical from "@core/layouts/layout-vertical/HatcheryLayoutVertical.vue";
// import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import { $themeConfig } from "@themeConfig";

export default {
	components: {
		// AppCustomizer,
		LayoutVertical,
		CompanyLayoutVertical,
		HatcheryLayoutVertical,
	},
	data() {
		return {
			showCustomizer: $themeConfig.layout.customizer,
			userData: JSON.parse(localStorage.getItem("userData")),
		};
	},
	computed: {
		layout() {
			if (this.userData.role == "super_admin") {
				if (this.$route.path.includes("manage-farm")) {
					return "hatchery-layout-vertical";
				} else if (this.$route.path.includes("manage-company")) {
					return "company-layout-vertical";
				} else {
					return "layout-vertical";
				}
			} else {
				if (this.$route.path.includes("manage-farm")) {
					return "hatchery-layout-vertical";
				} else {
					return "company-layout-vertical";
				}
			}
		},
	},
};
</script>
