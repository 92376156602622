<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-col>
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <!-- Bookmarks Container -->
        <!-- <bookmarks /> -->

        <div class="d-flex" v-if="userData.role == 'super_admin'">
          <!-- <div class="mr-1 ml-1" style="width: 300px">
						<v-select
						label="company_name"
						:value="$route.params.companyId"
						:options="companyList"
						:searchable="true"
						:reduce="(e) => `${e.id}`"
						@input="onCompanyChange"
						:clearable="true"
						></v-select>
					</div>
					<div class="ml-1" style="width: 300px">
						<v-select
						label="hatchery_name"
						:value="$route.params.farmId"
						:options="farmList"
						:searchable="true"
						:reduce="(e) => `${e.id}`"
						@input="onFarmChange($event, $route.params.companyId)"
						:clearable="true"
						></v-select>
					</div> -->
        </div>
        <div class="d-flex" v-else>
          <div class="company-label" style="width: 240px">
            <feather-icon icon="HomeIcon" size="16" class="mr-1" />
            {{ userData.company.company_name }}
          </div>
          <div class="ml-1" style="width: 300px">
            <v-select
              :value="$route.params.farmId || null"
              label="name"
              :options="farmList"
              :searchable="true"
              :reduce="(e) => `${e.id}`"
              @input="onFarmChange"
              :clearable="true"
            ></v-select>
          </div>
        </div>
      </div>
    </b-col>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale />
			<dark-Toggler class="d-none d-lg-block" /> -->
      <search-bar />
      <!-- <cart-dropdown /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BCol, BLink, BNavbarNav, BFormSelect } from "bootstrap-vue";
import vSelect from "vue-select";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import axiosIns from "@/libs/axios";
import vmson from "@/libs/empty";
import hatcheryUpdate from "@/libs/hatcheryUpdate";
export default {
  components: {
    BCol,
    BLink,
    // Navbar Components
    BNavbarNav,
    vSelect,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BFormSelect,
  },
  data() {
    return {
      companyList: [],
      farmList: [],
      userData: null,
    };
  },
  mounted() {
    hatcheryUpdate.$on("hatcheryUpdateEvent", (id) => {
      this.userData = JSON.parse(localStorage.getItem("userData"));
      this.getFarmList(this.userData.company.id);
    });
  },
  methods: {
    getCompanyList() {
      axiosIns
        .get("web/getCompanyList")
        .then((response) => {
          this.companyList = response.data.company_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getFarmList(company_id) {
      axiosIns
        .get("web/getFarmList", { params: { company_id: company_id } })
        .then((response) => {
          this.farmList = response.data.farm_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    onCompanyChange(companyId) {
      if (companyId) {
        this.$router.push({
          path: `/apps/manage-company/${companyId}/`,
        });
      } else {
        this.$router.push({
          path: `/`,
        });
      }
    },

    onFarmChange(farmId) {
      vmson.$emit("farmIdEvent", farmId);
      if (farmId) {
        this.$router.push({
          name: `manage-farm`,
          params: { farmId: farmId },
        });
      } else {
        if (this.userData.role == "super_admin") {
          this.$router.push({
            name: `manage-company`,
            params: { companyId: this.$route.params.companyId },
          });
        } else {
          this.$router.push({
            path: `/`,
          });
        }
      }
    },
    getAssignedFarmList() {
      axiosIns
        .get("web/getAssignedHatchery")
        .then((response) => {
          this.farmList = response.data.userHatchery;
          const farmId = this.farmList[0].id;

          localStorage.setItem("farmId", farmId);
          this.$router.push({
            name: `manage-farm`,
            params: { farmId: `${farmId}` },
          });
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    if (this.userData.role == "super_admin") {
      this.getCompanyList();
      if (this.$route.params.companyId) {
        this.getFarmList(this.$route.params.companyId);
      }
    } else if (this.userData.role == "owner") {
      this.getFarmList(this.userData.company.id);
    } else {
      this.getAssignedFarmList();
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    "$route.params.companyId": function (newValue, oldValue) {
      if (newValue) {
        this.getFarmList(newValue);
      } else {
        this.farmList = [];
      }
    },
  },
};
</script>
<style lang="scss">
.company-label {
  background-color: rgba(41, 109, 180, 0.2);
  color: rgba(41, 109, 180, 1);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
